import * as React from 'react'
import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore,
} from '../components/styles'
import styled from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'
import Card from '../components/Card'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import YouTube from 'react-youtube'
import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'
import CTACard from '../components/CTACard'
import SliderHero from '../components/SliderHero'

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-weight: 400;
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`
const Video = styled(YouTube)`
  margin-top: 25px;
  width: 90vw;
  height: 50.75vw;
  max-height: 576px;
  max-width: 1024px;
  border-radius: 8px;
`

const HomeHeader = styled.header`
  height: 80vh;
  padding-top: 7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    background-image: linear-gradient(-45deg, #fe9124 0%, #e71506 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: skewY(-8deg);
    transform-origin: left top 0px;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    max-width: 700px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 500;
    line-height: 150%;
    padding-right: 2rem;
    max-width: 500px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 9rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    /* padding-left: 10rem; */
  }
`

const ServicesContainer = styled(Container)`
  > div {
    margin-bottom: 6rem;
  }
`
const CardsContainer = styled(Container)`
  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const AboutContact = ({ data, children }) => (
  <React.Fragment>
    <StyledBackgroundSection
      image={data.drSellars.childImageSharp}
      title={'Meet Dr Graham Sellars'}
    >
      <p>
        Amongst the many things you may look for when choosing a Plastic Surgeon
        – reputation, experience, cost, compatibility – none can be more
        important than how well qualified the surgeon is to carry out the
        procedure you are considering. Always look for the title "Specialist
        Plastic Surgeon" to ensure your surgeon is fully qualified.
      </p>
      <p>
        In addition to the establishment and operation of his own practice since
        2002, Dr Sellars is one of the supervisors of Plastic Surgery training at the Sydney
        Adventist Hospital and has served on the hospital’s Medical Advisory
        Committee. He was Chairman of the NSW Chapter of the Australian Society of Plastic Surgeons from 2011 to 2013 and participates in the training of future Plastic Surgeons in Australia.
      </p>
      <p>
        After graduating from the University of NSW in 1992, Dr Sellars’ initial
        surgical training was at the Royal North Shore Hospital...
      </p>
      <Link to="/about">
        <Button outline={true} color="#fff" small={true}>
          Read more
        </Button>
      </Link>
    </StyledBackgroundSection>

    <Flex style={{ marginTop: 50 }} flexDirection="column" justifyContent="center">
      <h2 style={{ textAlign: 'center', marginBottom: '1em', fontWeight: 400 }}>
        Why Dr Graham Sellars
      </h2>

      <CardsContainer>
        <Cards>
          <Card
            color="primary"
            shadow={true}
            title="Skilled"
            content="You know you are in the safest hands with a Plastic Surgeon who is experienced, conservative and highly respected, with over 21 years in private practice. Dr Sellars is a fully qualified Plastic, Reconstructive and Cosmetic Surgeon FRACS (Plast).."
          />

          <Card
            color="tertiary"
            shadow={true}
            title="Trusted"
            content="We have a very long list of clients and patients delighted with our service and care. Take a look at just a few before and after photos in the gallery to see other’s journey to a new look."
          />

          <Card
            color="purple"
            shadow={true}
            title="Safe"
            content="Of course safety is our top priority and our entire team has decades of training and experience. Be assured your plastic surgery journey with us will not be rushed or pressured."
          />
        </Cards>

        {children}
      </CardsContainer>
    </Flex>

    <Images>
      <Img fluid={data.asps.childImageSharp.fluid} />
      <Img fluid={data.asaps.childImageSharp.fluid} />
      <Img fluid={data.fracs.childImageSharp.fluid} />
    </Images>

    <Contact />
  </React.Fragment>
)

/* prettier-ignore */
interface IProps {
  data: {
    womanWaterfall: {
      childImageSharp: {
        fluid: object
      }
    };
    woodsWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    waterBodyWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    faceCloseUp: {
      childImageSharp: {
        fluid: object
      }
    };
    drSellars: {
      childImageSharp: {
        fluid: object
      }
    };
    nonSurgical: {
      childImageSharp: {
        fluid: object
      }
    };
    asaps: {
      childImageSharp: {
        fluid: object
      }
    };
    asps: {
      childImageSharp: {
        fluid: object
      }
    };
    fracs: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => {
  if (!data) {
    console.log('Error: no data found')
    return null
  }

  return (
    <Layout header="light">
      <Helmet>
        <title>Plastic Surgery in Sydney, Australia - Dr. Graham Sellars</title>
        <meta
          name="description"
          content="Dr. Graham Sellars is a plastic and cosmetic surgeon in Sydney, NSW. Look your best with his plastic, reconstructive and cosmetic surgery procedures."
        />
      </Helmet>

      <SliderHero
        bgImage={[
          data.slideshowA.childImageSharp
          , data.slideshowB.childImageSharp
          , data.slideshowC.childImageSharp
          , data.slideshowD.childImageSharp
          , data.slideshowE.childImageSharp
          , data.slideshowF.childImageSharp
          , data.slideshowG.childImageSharp
          , data.slideshowH.childImageSharp
        ]}
        height="700px"
      >
        <span
          style={{
            fontSize: 22,
            color: '#fff',
            fontWeight: 600,
            marginBottom: 0,
          }}
        >
          Dr Graham Sellars
        </span>
        <h1 style={{ marginTop: 0 }}>Plastic Surgery Sydney</h1>
        <p>
          Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's
          North Shore.<br /><i>*Website contains adult content.</i>
        </p>
        <Flex>
          <Button color={'#fff'} small={true} outline={true}>
            Learn more
          </Button>
        </Flex>
      </SliderHero>

      {/* <AlternatingContent>
        <ContentCard
          image={data.woodsWoman.childImageSharp}
          title="Breast Surgery"
          buttons={[
            {
              text: 'Breast Augmentation',
              to: '/procedures/breast-augmentation',
            },
            { text: 'Breast Reduction', to: '/procedures/breast-reduction' },
            {
              text: 'Breast Reconstruction',
              to: '/procedures/breast-reconstruction',
            },
          ]}
        />
        <ContentCard
          image={data.faceCloseUp.childImageSharp}
          title="Facial Surgery"
          buttons={[
            // {
            //   text: 'Facelift',
            //   to: '/procedures/facelift',
            // },
            { text: 'Nose', to: '/procedures/nose-surgery' },
            {
              text: 'Ear Correction',
              to: '/procedures/prominent-ear-correction',
            },
            {
              text: 'Earlobe Repair',
              to: '/procedures/earlobe-repair',
            },
            {
              text: 'Eyelid Surgery',
              to: '/procedures/eyelid-surgery',
            },
          ]}
        />
        <ContentCard
          image={data.waterBodyWoman.childImageSharp}
          title="Body Surgery"
          buttons={[
            {
              text: 'Abdominoplasty (Tummy Tuck)',
              to: '/procedures/tummy-tuck',
            },
            // { text: 'Liposuction', to: '/procedures/liposuction' },
            {
              text: 'Mole Removal',
              to: '/procedures/mole-removal',
            },
            {
              text: 'Skin cancer surgery',
              to: '/procedures/skin-cancer-surgery',
            },
            {
              text: 'Scar Revision',
              to: '/procedures/scar-revision',
            },
          ]}
        />
      </AlternatingContent> */}

      {/* <BackgroundSection
        image={data.nonSurgical.childImageSharp}
        title={'Non-Surgical'}
        height="500px"
      >
        <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
          Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's
          North Shore.
        </p>
        {[
          {
            text: 'Treatments for Wrinkles and Volume Loss',
            to: '/non-surgical/dermal-fillers',
          },
          // {
          //   text: 'CoolSculpting',
          //   to: '/non-surgical/coolsculpting',
          // },
          {
            text: 'Skin Care',
            to: '/non-surgical/skin-treatments',
          },
          {
            text: 'Excess Underarm Sweating',
            to: '/non-surgical/excess-underarm-sweating',
          },
        ].map(({ to, text }) => (
          <Flex mr={2} mb={3} style={{ float: 'left' }}>
            <Link to={to}>
              <Button small={true} color="#fff" outline={true}>
                {text}
              </Button>
            </Link>
          </Flex>
        ))}
      </BackgroundSection> */}

      <VideoContainer>

        {/* <Video
          videoId="FZTt1ew2J50"
          opts={{
            height: '576',
            width: '1024',
            playerVars: {
              color: 'white',
              rel: 0,
            },
          }}
        /> */}
        <article>
          <p>
            <i>
              Welcome to one of Sydney’s leading plastic surgery centres.
            </i>
          </p>

          <p>

          </p>

          <p>
            Dr Sellars has been in private practice for 21 years and has
            performed thousands of procedures.
            Armed with ten years of post-graduate training, six of those specifically
            devoted to plastic surgery, Dr Sellars has a strong focus on breast surgery including reductions, augmentations and
            reconstruction as well as facial cosmetic surgery.
          </p>

          <p>
            Supported by a dedicated and highly qualified team who ensure every
            aspect of your treatment is nothing short of first class, all major
            surgery is carried out at The Sydney Adventist Hospital in Wahroonga
            and the SAN Day Surgery in Hornsby. We also have a fully-equipped
            procedure room in the SAN Clinic suite where minor surgery and
            cosmetic injectable treatments are performed.
          </p>

          <p>
            <i>
              Dr. Graham Sellars is a past Chairman of the NSW Chapter of the Australian Society
              of Plastic Surgeons
            </i>
          </p>

          <p>
            Dr Graham Sellars graduated from the University of NSW in 1992. He
            did initial surgical training at Hornsby and Royal North Shore
            Hospitals, then completed his specialist training in Plastic &
            Reconstructive surgery at several major teaching hospitals in
            Sydney, obtaining the RACS fellowship.
          </p>
        </article>
      </VideoContainer>

      <Container padY={false} medium={true} style={{ marginBottom: 40 }}>
        <SplitSection style={{ marginBottom: 70 }}>
          <SplitFigure>
            <Img
              style={{ paddingBottom: '65%' }}
              fluid={data.grahamSuit.childImageSharp.fluid}
              alt="Dr Graham Sellars"
            />
          </SplitFigure>
          <SplitContent>
            <h2>Meet Dr Sellars</h2>

            <p>
              Amongst the many things you may look for when choosing a Plastic Surgeon
              – reputation, experience, cost, compatibility – none can be more
              important than how well qualified the surgeon is to carry out the
              procedure you are considering. Always look for the title "Specialist
              Plastic Surgeon" to ensure your surgeon is fully qualified.
            </p>
            <p>
              In addition to the establishment and operation of his own practice since
              2002, Dr Sellars is one of the supervisors of Plastic Surgery training at the Sydney
              Adventist Hospital and has served on the hospital’s Medical Advisory
              Committee. He was Chairman of the NSW Chapter of the Australian Society of Plastic Surgeons from 2011 to 2013 and participates in the training of future Plastic Surgeons in Australia.
            </p>
            <p>
              After graduating from the University of NSW in 1992, Dr Sellars’ initial
              surgical training was at the Royal North Shore Hospital...
            </p>

            <ReadMore to="/about">
              Read More
              <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>

        <Img style={{ borderRadius: 8, maxWidth: '97.5%', margin: '20px auto' }} fluid={data.cosmetic.childImageSharp.fluid} />
      </Container>

    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {

    grahamSuit: file(relativePath: { eq: "graham-suit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slideshowE: file(relativePath: { eq: "slideshow/tonga.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowG: file(relativePath: { eq: "slideshow/team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowB: file(relativePath: { eq: "slideshow/clinic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowF: file(relativePath: { eq: "slideshow/chair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowD: file(relativePath: { eq: "slideshow/procedure.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowC: file(relativePath: { eq: "slideshow/desk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowA: file(relativePath: { eq: "slideshow/office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slideshowH: file(relativePath: { eq: "slideshow/slide-people-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    womanWaterfall: file(relativePath: { eq: "woman-waterfall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceCloseUp: file(relativePath: { eq: "face-close-up.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    woodsWoman: file(relativePath: { eq: "woods-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    waterBodyWoman: file(relativePath: { eq: "body-surgery.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cosmetic: file(relativePath: { eq: "homepage-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nonSurgical: file(relativePath: { eq: "non-surgical-woman.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
